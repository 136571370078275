import React, { useEffect } from "react";
import "./App.css";
import Nav from "./components/Nav";
import About from "./pages/About";
import Help from "./pages/Help";
// import AirportDetail from './components/Airport_detail'
// import AircraftDetail from './components/Aircraft_detail'
import Home from "./pages/Home";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import store from "./redux/store";
import { Provider } from "react-redux";

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
    ReactGA.pageview(window.location.pathname + window.location.search);
    // console.log('google analytics sent');
  });

  return (
    <Provider store={store}>
      <div className="app">
        {/* <div className="content"> */}
        <Nav />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/help" component={Help} />
          <Route path="/about" component={About} />
          {/* <Route exact path='/airport-data' component={AirportData}/> */}
          <Route path="/airport/:icao/:acs?" component={Home} />
          {/* <Route exact path='/aircraft' component={AircraftData}/> */}
          <Route path="/aircraft/:ac_code/:aps?" component={Home} />
          <Redirect to="/" />
        </Switch>
        <footer className="">
          <p>
            Avionia {new Date().getFullYear()} ver: {process.env.REACT_APP_VERSION} | Experimental
            usage only | Data not to be used for real-life calculations
          </p>
        </footer>
        {/* </div> */}
      </div>
    </Provider>
  );
}

export default withRouter(App);
